import React from 'react';

import { NavLink } from 'react-router-dom';

export const Samples = () => {
    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-xxl-3">
                        <div className="card">
                            <NavLink to='/samples/countdown-000' className="cardTitle">Countdown</NavLink>
                            <p>A simple countdown</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
