import React from 'react'
import styles from './header.module.css'
import { NavLink } from 'react-router-dom'

export const Header = () => {
    return (
        <header className={styles["header"]}>
            <div className="container">
                <nav className={styles["nav"]}>
                    <NavLink to='/' className={styles["brand-link"]}>Tacalaca</NavLink>
                    <ul className={styles["nav-list"]}>
                        <li className={`${styles["nav-item"]} ${styles["hidden"]}`}><NavLink to={'/projects'} className={styles["nav-link"]}>Projects</NavLink></li>
                        <li className={`${styles["nav-item"]} ${styles["hidden"]}`}><NavLink to={'/samples'} className={styles["nav-link"]} >Samples</NavLink></li>

                    </ul>
                </nav>
            </div>
        </header>

    )
}
