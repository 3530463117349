import './App.css';
import { Header } from './components/header/Header'
import { Footer } from './components/footer/Footer'
import { Home } from './components/home/Home'
import { Samples } from './components/samples/Samples'
import { Countdown000 } from './components/samples/Countdown000/Countdown000';

import { NotFound } from './components/notFound/NotFound'
import { BrowserRouter, Routes, Route } from 'react-router-dom';



function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path='/' element={<Samples />}></Route>
        <Route path='/samples' element={<Samples />}></Route>
        <Route path='/samples/countdown-000' element={<Countdown000 />}></Route>
        <Route path='*' element={<NotFound />}></Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
