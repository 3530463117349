import React from 'react'
import s from './footer.module.css'

export const Footer = () => {
    return (
        <footer className={s["footer"]}>
            <div className="container">
                <p>2023 - All rights reserved &copy;</p>
            </div>
        </footer>
    )
}
