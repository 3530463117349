import React from 'react'
import s from './countdown000.module.css';

export const Countdown000 = () => {

    let hours, minutes, seconds;
    let intervalId;

    function start() {
        hours = Number(document.getElementById('hours').value);
        minutes = Number(document.getElementById('minutes').value);
        seconds = Number(document.getElementById('seconds').value);

        updateCountdown(hours, minutes, seconds);
        console.log('Start');
        intervalId = setInterval(countdown, 1000);
    }

    function countdown() {
        if (seconds != 0) {
            seconds--;
        } else {
            if (minutes != 0) {
                minutes--;
                seconds = 59;
            } else {
                if (hours != 0) {
                    hours--;
                    minutes = 59;
                    seconds = 59;
                } else {
                    clearInterval(intervalId);
                }
            }
        }

        updateCountdown(hours, minutes, seconds);
    }

    function updateCountdown(hours, minutes, seconds) {
        document.getElementById('time').innerText = String(hours).padStart(2, '0') + ":" + String(minutes).padStart(2, '0') + ":" + String(seconds).padStart(2, '0');
    }



    return (
        <section>
            <div className="container">
                <div className="example-wrapper">
                    <div className="row">
                        <div className="col-12">

                            <p className={s.time} id="time">00:00:00</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-3">
                            <div className="input-wrapper">
                                <input className={s.input} type="number" name="hours" id="hours" min="0" max="99" defaultValue="0" />
                                horas
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="input-wrapper">
                                <input className={s.input} type="number" name="minutes" id="minutes" min="0" max="59" defaultValue="0" />
                                minutos
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="input-wrapper">
                                <input className={s.input} type="number" name="seconds" id="seconds" min="0" max="59" defaultValue="0" />
                                segundos
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <button className={s.button} onClick={start}>Comenzar</button>
                        </div>
                    </div>

                </div>


            </div>
        </section>
    )
}
